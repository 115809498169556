import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/styled-engine';
import './i18n';
import RTL from './components/RTL';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import { dashboardApi } from './api/dashboardApi';
import { toursApi } from './api/toursApi';
import {
  updateCompanyInfo,
  updateLinkOfStep,
  updateSiteInfo,
  updateStatus,
  updateStatusOfStep,
  updateStepsText,
  updatePastelHelpResources
} from './slices/dashboard';
import { useDispatch } from './store';
import { SnackbarComponent } from './contexts/SnackbarContext';
import { SnackbarBoxComponent } from './contexts/SnackbarBoxContext';
import { BackdropComponent } from './contexts/BackdropContext';
import { globalApi } from './api/globalApi';
import { useIntercom } from 'react-use-intercom';
import { hotjar } from 'react-hotjar';
import { QueryClient, QueryClientProvider } from "react-query";
import { THEMES } from './constants';
import "./index.css";

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const displayingWidget = settings.theme === THEMES.WIDGET;
  const auth = useAuth();
  const queryClient = new QueryClient();

  useScrollReset();

  useEffect(() => {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const { user, isAuthenticated } = useAuth();
  const { boot: bootIntercom } = useIntercom();
  const dispatch = useDispatch();

  useEffect(() => {
    if (Boolean(user) && isAuthenticated && !displayingWidget) {
      // H.identify(user.email, {
      //   id: user.id,
      //   name: user.email
      // });
      if ('DD_RUM' in window) {
        window.DD_RUM.setUser({
          id: user.id,
          name: user.email,
          email: user.email,
        });
      }
    }
  });
  useEffect(() => {
    if (Boolean(user) && isAuthenticated) {
      (async () => {
        const [company, toursCount, globalSettings] = await Promise.all(
          [
            dashboardApi.getAllCompanyInfo(user.company),
            toursApi.count(),
            globalApi.findSettings(),
          ]
        );
        const { website, ...companyInfo } = company;
        const getStatusOfGeneralInfo = () => {
          if (website) {
            return Object.values(website).some((value) => value !== null);
          }
          return false;
        };
        const generalStatus = getStatusOfGeneralInfo();
        dispatch(updateSiteInfo(website));
        dispatch(updateCompanyInfo(companyInfo));
        dispatch(updateStatusOfStep({ id: 0, status: website && website.reviewed }));
        dispatch(updateStatusOfStep({ id: 1, status: generalStatus }));
        dispatch(updateStatusOfStep({ id: 2, status: toursCount !== 0 }));
        dispatch(updateStatusOfStep({ id: 3, status: website && website.watchedVideo }));
        dispatch(updateStepsText(website?.status || 'created'));
        dispatch(updateLinkOfStep({ id: 3, link: globalSettings?.platformIntroductionVideo || '/dashboard/overview' }));
        dispatch(updateStatus(website?.status || 'created'));
        dispatch(updatePastelHelpResources(globalSettings?.pastelHelpResources));

        if (companyInfo?.features?.website) {
          bootIntercom({ name: user ? `${user.firstName} ${user.lastName}` : '' });
        }
      })();
    }
  }, [user, isAuthenticated, bootIntercom]);

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <RTL direction={settings.direction}>
            <CssBaseline />
            <BackdropComponent />
            <SnackbarComponent />
            <SnackbarBoxComponent />
            {(auth.isInitialized || displayingWidget) ? content : <SplashScreen />}
          </RTL>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

export default App;
