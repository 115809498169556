import strapiAxiosInstance from './strapiAxiosInstance';
import { v4 as uuidv4 } from 'uuid';

class AuthApi {
  /**
   *
   * @param {string} companyName
   * @param {string} offer
   * @param {string} [customOffer=''] - if offer equal Other this must be defined
   * @param {string} brandColor
   * @param {string} url
   * @param {string} referralSource
   * @returns {Promise<string | number>}
   */
  async registerCompany({ companyName, offer, customOffer, brandColor, url, referralSource }) {
    return (await strapiAxiosInstance('/companies', {
      method: 'POST',
      timeout: 0,
      data: {
        companyName,
        brandColor,
        offer,
        customOffer,
        url,
        referralSource,
      }
    })).data.id;
  }

  /**
   *
   * @param {string} companyName
   * @returns {Promise<string | number>}
   */
  async registerSMMCompany({ companyName, samcartOrderId }) {
    return (await strapiAxiosInstance('/companies/createSMMCompany', {
      method: 'POST',
      timeout: 0,
      data: {
        companyName,
        samcartOrderId
      }
    })).data.id;
  }

  /**
   *
   * @param {string} email
   * @param {string} firstName
   * @param {string} password
   * @param {string} lastName
   * @param {number | string} companyId
   * @returns {Promise<{ jwt: string, user: Object.<string, any>} | Object>}
   */
  async register({ email, password, firstName, lastName, companyId }) {
    return (await strapiAxiosInstance('/auth/local/register', {
      method: 'POST',
      data: {
        username: uuidv4(),
        email,
        password,
        firstName,
        lastName,
        company: companyId,
      },
    })).data;
  }

  /**
   *
   * @param {string} email
   * @returns {Promise<void>}
   */
  async sendEmailConfirmation(email) {
    await strapiAxiosInstance.post('/auth/send-email-confirmation', { email });
  }

  /**
   *
   * @param {string} userId
   * @param {string} email
   * @returns {Promise<void>}
   */
  async reSendUserEmail(userId, email) {
    await strapiAxiosInstance.post('/auth/resend-to-new-email', { id: userId, email });
  }

  /**
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise<{jwt: string, user: Object}>}
   */
  async login({ email, password }) {
    const response = await strapiAxiosInstance.post('/auth/local', {
      identifier: email,
      password,
    });
    return { ...response.data,
      user: {
        ...response.data.user,
        company: response.data.user.company.id
      } };
  }

  /**
   *
   * @param {string} token
   * @return {Promise<{jwt: string, user: Object}>}
   */
  async autoLogin(token) {
    const response = await strapiAxiosInstance.get('/auth/auto-login', { params: { auth: token } });
    return response.data;
  }

  /**
   *
   * @param {string} email
   * @returns {Promise<void>}
   */
  async forgotPassword(email) {
    await strapiAxiosInstance.post('/auth/forgot-password', { email });
  }

  /**
   *
   * @param {string} password
   * @param {string} passwordConfirmation
   * @param {string} code - token from query param
   * @returns {Promise<void>}
   */
  async resetPassword({ password, passwordConfirmation, code }) {
    await strapiAxiosInstance.post('/auth/reset-password', {
      code,
      password,
      passwordConfirmation,
    });
  }

  /**
   *
   * @param {string} accessToken
   * @returns {Promise<Object>}
   */
  async me(accessToken) {
    return (await strapiAxiosInstance.get('/users/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      }
    })).data;
  }

  /**
   *
   * @param {number} id - user id
   * @param {Record<string, any>} data - user data
   * @returns {Promise<Record<string, any>>}
   */
  async updateMe(id, data) {
    return (await strapiAxiosInstance(`/users/${id}`, {
      method: 'PUT',
      data,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    })).data;
  }
}

export const authApi = new AuthApi();
