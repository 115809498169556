import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const InstantInsightAuthGuard = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();

  const features = (auth?.user as any)?.companyData?.features || {};
  if (!auth.isAuthenticated || (features.website && !features.instantInsights)) {
    // if user is not auth or auth but enabled only for website
    localStorage.removeItem("accessToken");
    return (
      <Navigate
        to={`/authentication/instant-insights/google/connect`}
      />
    );
  }

  return <>{children}</>;
};

export default InstantInsightAuthGuard;
