import { useState, useEffect, useCallback, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@mui/material';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import CloseModal from '../components/widgets/modals/Modal10';
import DialogContent from '@mui/material/DialogContent';

export const ClosePageContext = createContext({
  isBlocked: true,
// eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsBlocked: (selection) => {},
});

function useCallbackPrompt(isBlocked) {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (
        !confirmedNavigation
            && nextLocation.location.pathname !== location.pathname
      ) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, isBlocked);

  return [showPrompt, confirmNavigation, cancelNavigation];
}

export const ClosePageProvider = ({ children }) => {
  const [isBlocked, setIsBlocked] = useState(true);
  const handleSetIsBlocked = useCallback((value) => setIsBlocked(value), [isBlocked, setIsBlocked]);
  return (
    <ClosePageContext.Provider value={{ isBlocked, setIsBlocked: handleSetIsBlocked }}>
      {children}
      <ClosePageModal />
    </ClosePageContext.Provider>
  );
};

ClosePageProvider.propTypes = {
  children: PropTypes.node,
};

const ClosePageModal = () => {
  const { isBlocked } = useContext(ClosePageContext);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isBlocked);
  useEffect(() => {
    const callback = (event) => {
      event.preventDefault();
    };
    window.addEventListener('beforeunload', callback, { capture: true });
    return () => window.removeEventListener('beforeunload', callback, { capture: true });
  }, []);
  return (
    <Modal open={showPrompt}>
      <DialogContent>
        <CloseModal
          confirm={confirmNavigation}
          cancel={cancelNavigation}
        />
      </DialogContent>
    </Modal>
  );
};
