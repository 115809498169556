import { experimentalStyled } from "@mui/material/styles";
import InstantInsightSidebar from "./InstantInsightSidebar";
import { useState } from "react";
import InstantInsightNavbar from "./InstantInsightNavbar";
import { instantInsightApi } from "@src/api/instantInsightsApi";
import { useQuery } from "react-query";
import InstantInsightsContainer from "@src/ui/pages/instant-insights/instant-insights.container";
import { Box } from "@mui/material";

interface InstantInsightLayoutWrapperProps {
  isSidebarHidden: boolean;
}

const borderOverlayCss = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  height: { lg: "15px", md: "15px", sm: "5px", xs: "5px" },
  backgroundColor: "#FF790B",
  zIndex: 9999,
};

const whiteBoxCss = {
  width: "100%",
  height: "440px",
  backgroundColor: "white",
  maxWidth: "1444px",
  zIndex: 1,
  position: "absolute",
  top: 0,
  display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
};

const InstantInsightLayoutRoot = experimentalStyled("div")({
  backgroundColor: "#FF790B",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflow: "auto",
  width: "100%",
  fontFamily: "Lato !important",
  flex: "1 1 auto",
  minHeight: "100%",
  position: "relative",
  borderLeft: "5px solid #FF790B",
  borderRight: "5px solid #FF790B",
});

const InstantInsightLayoutWrapper = experimentalStyled(
  "div"
)<InstantInsightLayoutWrapperProps>({
  backgroundColor: "#E8EBEF",
  maxWidth: "1444px",
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  width: "100%",
});

const InstantInsightLayoutContent = experimentalStyled("div")(({ theme }) => ({
  flex: "1 1 auto",
  height: "100%",
  position: "relative",
  paddingBottom: "150px",
  paddingTop: "150px",
  display: "flex",
  justifyContent: "center",
  marginBottom: "100px", // required so that intercom widgets in the bottom right corner don't overlap with pagination
  WebkitOverflowScrolling: "touch",
  [theme.breakpoints.down("md")]: {
    paddingTop: "0px",
    paddingBottom: "50px",
  },
  zIndex: 2,
}));

const InstantInsightLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);
  const [isSidebarHidden, setSidebarIsHidden] = useState<boolean>(false);

  const [pollForGenerationStatus, setPollForGenerationStatus] =
    useState<boolean>(true);

  const { data: IICompany } = useQuery(
    "instantInsights",
    instantInsightApi.getInstantInsights,
    {
      refetchInterval: 2000,
      enabled: pollForGenerationStatus,
      onSuccess: async (data) => {
        if (data?.reportsGenerationStatus === "idle") {
          setPollForGenerationStatus(false);
        } else {
          setPollForGenerationStatus(true);
        }
      },
    }
  );

  return (
    <InstantInsightLayoutRoot>
      <Box sx={borderOverlayCss}></Box>
      <InstantInsightLayoutWrapper isSidebarHidden={isSidebarHidden}>
        <InstantInsightSidebar
          IICompany={IICompany}
          onMobileClose={() => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <InstantInsightLayoutContent>
          <InstantInsightsContainer
            IICompany={IICompany}
            pollForGenerationStatus={pollForGenerationStatus}
          />
          <InstantInsightNavbar
            onSidebarMobileOpen={() =>
              setIsSidebarMobileOpen(!isSidebarMobileOpen)
            }
          />
        </InstantInsightLayoutContent>
      </InstantInsightLayoutWrapper>
      {/* <Box sx={whiteBoxCss}></Box> */}
      {!isSidebarMobileOpen && (
        <Box sx={{ ...borderOverlayCss, top: "auto", bottom: 0 }}></Box>
      )}
    </InstantInsightLayoutRoot>
  );
};

export default InstantInsightLayout;
