import strapiAxiosInstance from './strapiAxiosInstance';

/**
 *
 * @param {{
 * file: File,
 * [ref]: string,
 * [refId]: string,
 * [field]: string,
 * [fileInfo]: {[alternativeText]: string, [caption]: string, [name]: string}
 * }} data
 * @return {Promise<Record<string, any>>}
 */
const uploadMedia = async (data) => {
  const formData = new FormData();
  const { file, ref, refId, field, fileInfo } = data;
  formData.append('files', file, file.name);
  if (ref) formData.append('ref', ref);
  if (refId) formData.append('refId', refId);
  if (field) formData.append('field', field);
  if (fileInfo) {
    formData.append('fileInfo', JSON.stringify(fileInfo));
  }
  const response = await strapiAxiosInstance.post('/upload', formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    }
  });
  return response.data[0];
};

/**
 *
 * @param {number} id
 * @param {{ [alternativeText]: string, [caption]: string, [name]: string }} fileInfo
 * @return {Promise<any>}
 */
const updateMedia = async (id, fileInfo) => {
  const formData = new FormData();
  formData.append('fileInfo', JSON.stringify(fileInfo));
  return (await strapiAxiosInstance.post('/upload', formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    },
    params: {
      id,
    }
  })).data;
};

/**
 *
 * @param {number} id
 * @return {Promise<void>}
 */
const destroyMedia = async (id) => (await strapiAxiosInstance.delete(`/upload/files/${id}`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  }
})).data;

export { uploadMedia, updateMedia, destroyMedia };
