import { createSlice } from '@reduxjs/toolkit';

const statuses = ['created', 'constructing', 'moderating', 'constructed', 'published'];

const initialState = {
  isShowCongrats: true,
  status: 'created',
  steps: [
    {
      id: 3,
      name: 'Gondola Platform Introduction',
      status: false,
      link: '/dashboard/overview',
      component: 'a',
    },
    {
      id: 1,
      name: 'Submit General Business Information',
      status: false,
      link: '/dashboard/overview/general-info',
    },
    {
      id: 2,
      name: 'Add a tour',
      status: false,
      link: '/dashboard/overview/tours',
    },
    {
      id: 0,
      name: 'Review website text, layout and design',
      status: false,
      link: '/dashboard/overview/review',
    },
  ],
  company: {
    id: null,
    companyName: '',
    offer: '',
    customOffer: '',
    brandColor: '',
    website: null,
    dns: null,
    usePastelUrl: '',
    umamiShareId: '',
    review: null,
    features: {
      id: null,
      website: null,
      socialMedia: null,
    }
  },
  checkfront: {
    checkfrontUrl: '',
  },
  peek: {
    email: '',
    password: ''
  },
  tripadvisor: {
    tripadvisorUrl: '',
  },
  confirmBoxContent: {
    title: '',
    message: ''
  },
  pastelHelpResources: []
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateIsShowCongrats(state, action) {
      state.isShowCongrats = action.payload;
    },
    updateStatus(state, action) {
      if (statuses.findIndex((status) => status === action.payload) !== -1) {
        state.status = action.payload;
      }
    },
    updateCompanyInfo(state, action) {
      state.company = { ...state.company, ...action.payload };
    },
    updateStatusOfStep(state, action) {
      const { id, status } = action.payload;
      state.steps = state.steps.map((step) => (step.id === id ? { ...step, status } : step));
    },
    updateLinkOfStep(state, action) {
      const { id, link } = action.payload;
      state.steps = state.steps.map((step) => (step.id === id ? { ...step, link } : step));
    },
    updateSiteInfo(state, action) {
      state.company.website = action.payload;
    },
    updateStepsText(state, action) {
      if (action.payload === 'published') {
        state.steps[1].name = 'Edit some general info';
        state.steps[2].name = 'Edit your tours';
      }
    },
    updateCheckfront(state, action) {
      state.checkfront = action.payload;
    },
    updatePeek(state, action) {
      state.peek = action.payload;
    },
    updateTripadvisor(state, action) {
      state.tripadvisor = action.payload;
    },
    updateReview(state, action) {
      state.company.review = action.payload;
    },
    updateConfirmBoxContent(state, action) {
      state.confirmBoxContent = action.payload;
    },
    updatePastelHelpResources(state, action) {
      state.pastelHelpResources = action.payload;
    }
  }
});

export const { reducer } = slice;

export const {
  updateSiteInfo,
  updateIsShowCongrats,
  updateStatus,
  updateStatusOfStep,
  updateCompanyInfo,
  updateStepsText,
  updateLinkOfStep,
  updateCheckfront,
  updatePeek,
  updateTripadvisor,
  updateReview,
  updateConfirmBoxContent,
  updatePastelHelpResources
} = slice.actions;


export const getCompanyInfo = (state) => ({
  ...state.dashboard.company,
  features: state.dashboard.company.features || {
    id: null,
    website: false,
    socialMedia: false,
    instantInsights: false,
  },
});

export const getCheckfront = (state) => state.dashboard.checkfront;

export const getPeek = (state) => state.dashboard.peek;

export const getTripadvisor = (state) => state.dashboard.tripadvisor;

export const getSteps = (state) => state.dashboard.steps;

export const getIsShowCongrats = (state) => state.dashboard.isShowCongrats;

export const getStatus = (state) => state.dashboard.status;

export const getConfirmBoxContent = (state) => state.dashboard.confirmBoxContent;

export const getPastelHelpResources = (state) => state.dashboard.pastelHelpResources;
