import { baseUrl } from '@src/utils/baseUrl';
import axios from 'axios';

const instance = axios.create({
  baseURL: `${baseUrl}/api-strapi/v2/`,
});

export const authenticatedInstance = (urlPrefix: string) => axios.create({
  baseURL: `${baseUrl}/api-strapi/v2/${urlPrefix}`,
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

export default instance;
