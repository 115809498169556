import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authApi } from '../api/authApi';
import useQuery from '../hooks/useQuery';
import { usePostHog } from 'posthog-js/react';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  }
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  loginWithToken: (jwt, user) => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerSMM: (params) => Promise.resolve(),
  registerCompany: (jwt, user) => Promise.resolve(),
  sendEmailConfirmation: () => Promise.resolve(),
  reSendUserEmail: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const query = useQuery();
  const posthog = usePostHog();

  const posthogIdentify = (user) => {
    console.log('posthogIdentify', user);
    if (process.env.NODE_ENV === 'production') {
      posthog?.group('company', user.company, user.companyData);
      posthog?.identify(
        user.id,
        { email: user.email, firstName: user.firstName, lastName: user.lastName }
      );
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        if (query.get('auth')) {
          const { jwt, user, admin } = await authApi.autoLogin(query.get('auth'));

          localStorage.setItem('accessToken', jwt);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });

          posthog?.identify(
            admin.id,
            { email: admin.email, firstName: admin.firstName, lastName: admin.lastName }
          );

          return;
        }

        const accessToken = localStorage.getItem('accessToken');

        console.log('accessToken', accessToken);
        if (accessToken) {
          const [, encodedPayload] = accessToken.split('.');
          const payload = JSON.parse(Buffer.from(encodedPayload, 'base64').toString('ascii'));
          const user = await authApi.me(accessToken);

          // skips identifying as user if admin, so we retain the correct posthog session for feature flags
          if (!payload.adminId) {
            posthogIdentify(user);
          }

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const { jwt, user } = await authApi.login({ email, password });

    loginWithToken(jwt, user);
  };

  const loginWithToken = async (jwt, user) => {
    localStorage.setItem('accessToken', jwt);

    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });

    posthogIdentify(user);
  };

  const logout = async () => {
    localStorage.removeItem('accessToken');
    dispatch({ type: 'LOGOUT' });

    posthog.reset();
  };

  const register = async ({ email, firstName, password, lastName, companyId }) => {
    return await authApi.register({ email, firstName, password, lastName, companyId });
  };

  const registerSMM = async ({ email, firstName, password, lastName, companyName, samcartOrderId }) => {
    const company = await authApi.registerSMMCompany({ companyName, samcartOrderId });
    return await register({ email, firstName, password, lastName, companyId: company });
  };

  // eslint-disable-next-line no-return-await
  const registerCompany = async ({ companyName, offer, customOffer, brandColor, url, referralSource }) => await authApi.registerCompany(
    { companyName, offer, customOffer, brandColor, url, referralSource }
  );

  const sendEmailConfirmation = async (email) => {
    await authApi.sendEmailConfirmation(email);
  };

  const reSendUserEmail = async (userId, email) => {
    await authApi.reSendUserEmail(userId, email);
  };

  const forgotPassword = async (email) => {
    await authApi.forgotPassword(email);
  };

  const resetPassword = async ({ password, passwordConfirmation, code }) => {
    await authApi.resetPassword({ password, passwordConfirmation, code });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        loginWithToken,
        logout,
        register,
        registerSMM,
        registerCompany,
        sendEmailConfirmation,
        reSendUserEmail,
        forgotPassword,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
