import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Logo from '../Logo';
import NavSection from './nav/NavSection';
import Scrollbar from '../Scrollbar';
import { dashboardRoutes, smmDashboardRoutes } from './sidebar/routes';
import { useQuery } from 'react-query';
import { dashboardApi } from '@src/api/dashboardApi';
import useAuth from '@src/hooks/useAuth';


const DashboardSidebar = (props) => {
  const {  user } = useAuth();
  const { data: companyInfo } = useQuery('companyInfo', () => dashboardApi.getAllCompanyInfoNew(user.company));

  const isSMMUser = companyInfo?.features?.website ? false :  companyInfo?.features?.socialMedia;

  const { onMobileClose, openMobile, userIsFeatureFlagged, companyName } = props;
  const location = useLocation();

  const lgMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (location.pathname === '/dashboard/overview/review' && !isHidden) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, [location.pathname]);


  // feature flag route filtering
  const routesGeneral = dashboardRoutes[0].items;
  const routesAdmin = dashboardRoutes[1].items;

  const filteredRoutes =
    [
      { name: 'general', items: routesGeneral.filter(item => companyInfo?.features?.socialMedia ? !item.featureFlagged : !item.featureFlagged && item.title !== "Echo Social AI")},
      { name: 'admin', items: routesAdmin.filter(item => !item.featureFlagged) }
    ];

  const navRoutes = isSMMUser ? smmDashboardRoutes:  userIsFeatureFlagged ?
    filteredRoutes : dashboardRoutes;

  const TruncatedText = ({ text, maxLength }) => {
    if (text.length <= maxLength) {
      return <span>{text}</span>;
    } else {
      const truncatedText = text.substring(0, maxLength) + '...';
      return <span title={text}>{truncatedText}</span>;
    }
  };

  TruncatedText.propTypes = {
    text: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired,
  };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        zIndex: 2
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 5
          }}
        >
          <RouterLink to="/">
            {isSMMUser ?
              <img
                width="180"
                src="/static/echo-social-logo.png"
              />
               :
              <Logo
                sx={{
                  height: 'auto',
                  width: 160
                }}
              />
            }
          </RouterLink>
        </Box>

        {/* TODO put a loader here or prevent rest of sidebar from rendering until company name is present? Currently fixed height to prevent pushing content down when loaded. */}
        <Typography mx={2} align="center" maxHeight={60}>
          <TruncatedText text={companyName} maxLength={50} />
        </Typography>
        <hr />


        {Object.keys(navRoutes).map((section, index) => (
          <Box sx={{ pl: 2 }} key={index}>
            <NavSection
              title={navRoutes[section].name}
              key={navRoutes[section]}
              pathname={navRoutes[section].items[index]?.path}
              disabled={navRoutes[section].items[index]?.disabled}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...navRoutes[section]}
            />
          </Box>
        ))}

      </Scrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={!lgMd ? onMobileClose : null}
      open={!lgMd ? openMobile : true}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 220,
          height: lgMd ? '100% !important' : null,
          display: lgMd && isHidden ? 'none' : null
        }
      }}
      variant={lgMd ? 'permanent' : 'temporary'}
    >
      {content} {/* TODO what's the point of declaring and rendering content in the same file? */}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  companyName: PropTypes.string,
  userIsFeatureFlagged: PropTypes.bool
};

export default DashboardSidebar;
