import {
	Grid,
	Typography,
	AlertTitle,
	Box,
	ButtonBase
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

const AlertBox = ({ title, message, type, onClose, children }) => {
	const showLargeIcon = useMediaQuery('(min-width:600px)');
	const typeColors = {
		'success': '#02C39A4D',
		'error': '#F467324D',
	};
	const typeBorderColors = {
		'success': '#02C39A',
		'error': '#F46732',
	};

	// TODO add logic here to extend different icons

	return (
		<Grid
			alignItems="center"
			container
			justifyContent="space-between"
			spacing={3}
			xs={12}
		>
			<Grid
				item
				xs={12}
			>
				<Box
					elevation={3}
					variant="outlined"
					sx={{ border: '1px solid ' + typeBorderColors[type], backgroundColor: typeColors[type], borderRadius: .5, padding: '18px' }}
					icon={null}
				>
					<Grid sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
						<Grid container direction="row" gap={2}>
							{( type === 'success' ? (
									<CheckBoxIcon
										sx={{ fontSize: showLargeIcon && 40, alignSelf: 'center', color: '#F46732' }}
									/>
								) : (type === 'error' ? (
									<ReportProblemIcon 
										sx={{ fontSize: showLargeIcon && 40, alignSelf: 'center', color: '#F46732' }}
									/>
									) : ''
								)
							)}
							<Box sx={{ display: "flex", flexDirection: "column", color: '#333' }}>
								<AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
								<Typography>
									{message}
								</Typography>
							</Box>
						</Grid>

						{children}
						<ButtonBase onClick={onClose}>
							<CancelIcon
								sx={{ fontSize: 25, alignSelf: 'center', color: '#F46732' }} />
						</ButtonBase>
					</Grid>
				</Box>
			</Grid>
		</Grid>
	);
};

AlertBox.propTypes = {
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	type: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node
};

AlertBox.defaultProps = {
	type: 'success',
};

export default AlertBox;
