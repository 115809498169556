import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Scrollbar from "../Scrollbar";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { flatten } from "lodash";
import CloseIcon from "@mui/icons-material/Close";

type InstantInsightSidebarProps = {
  IICompany: any;
  onMobileClose: () => void;
  openMobile: boolean;
};

const InstantInsightSidebar = ({
  IICompany,
  onMobileClose,
  openMobile,
}: InstantInsightSidebarProps) => {
  const location = useLocation();

  const lgMd = useMediaQuery((theme: any) => theme.breakpoints.up("md"));
  const [isHidden, setIsHidden] = useState(false);

  const handleNavigate = (value: string) => {
    window.location.href = `#${value}`;
    if (!lgMd) {
      onMobileClose();
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const routes = flatten(
    (IICompany?.reports || []).map((report: any) => report.content || [])
  ).map((block: any) => ({
    title: block.title,
    value: `${block?.title?.replace(/\s/g, "-")?.toLowerCase()}`,
  }));

  const content = (
    <Box
      sx={{
        height: "100vh",
        zIndex: 2,
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box sx={{ height: "100%", display: "flex", alignItems: "flex-end" }}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              pl: 2,
              height: {
                lg: "calc(100% - 440px)",
                md: "calc(100% - 440px)",
                sm: "100%",
                xs: "100%",
              },
            }}
          >
            <List>
              {routes.map((route, index) => {
                return (
                  <ListItem
                    disablePadding
                    key={index}
                    data-to-scrollspy-id={route.value}
                  >
                    <ListItemButton onClick={() => handleNavigate(route.value)}>
                      <ListItemIcon sx={{ mr: 1 }}>
                        <HorizontalRuleIcon className="scrollspy-icon" />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            fontFamily={"Lato"}
                            className="scrollspy-title"
                            sx={{ textTransform: "uppercase" }}
                          >
                            {route.title}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Box>
      </Scrollbar>
    </Box>
  );

  const mobileContent = (
    <Box
      sx={{
        height: "100%",
        zIndex: 2,
        paddingTop: 5,
        paddingBottom: 5,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        sx={{ pl: 2, pr: 2 }}
      >
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton onClick={onMobileClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Scrollbar options={{ suppressScrollX: true }}>
          <List>
            {routes.map((route, index) => {
              return (
                <>
                  <ListItem disablePadding key={index}>
                    <ListItemButton onClick={() => handleNavigate(route.value)}>
                      <ListItemIcon sx={{ mr: 2 }}>
                        <Typography
                          color="#FF790B"
                          sx={{
                            fontFamily: "serif",
                            fontSize: "25px",
                            fontWeight: "bold",
                          }}
                        >
                          {index + 1 < 10 && 0}
                          {index + 1}
                        </Typography>
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            fontFamily={"Lato"}
                            sx={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                            }}
                            variant="subtitle1"
                          >
                            {route.title}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider color="black" sx={{ height: "1px", ml: 2, mr: 2 }} />
                </>
              );
            })}
          </List>
        </Scrollbar>
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgMd ? openMobile : true}
      sx={{
        position: "fixed",
        marginRight: { md: 5 , sm: 5},
        zIndex: 100,
      }}
      PaperProps={{
        sx: {
          border: "none",
          backgroundColor: { lg: "transparent", md: "transparent" },
          width: lgMd ? 220 : "100%",
          height: lgMd ? "100% !important" : null,
          display: lgMd && isHidden ? "none" : null,
          position: { lg: "relative", md: "relative" },
        },
      }}
      variant={lgMd ? "permanent" : "temporary"}
    >
      {lgMd ? content : mobileContent}{" "}
      {/* TODO what's the point of declaring and rendering content in the same file? */}
    </Drawer>
  );
};

export default InstantInsightSidebar;
