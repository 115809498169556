import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { SettingsProvider } from './contexts/SettingsContext';
import store from './store';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { SnackbarBoxProvider } from './contexts/SnackbarBoxContext';
import { BackdropProvider } from './contexts/BackdropContext';
import { IntercomProvider } from 'react-use-intercom';
import { ErrorBoundary } from '@highlight-run/react';
import { datadogRum } from '@datadog/browser-rum';

import { datadogLogs } from '@datadog/browser-logs';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react';

posthog.init(
  process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
  {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  }
);

const isWidget = window.location.pathname.startsWith('/widget');
const ddService = isWidget ? 'payments-widget' : 'gondola-platform';
const ddClientToken = isWidget ? process.env.REACT_APP_DATADOG_RUM_WIDGET_CLIENT_TOKEN : process.env.REACT_APP_DATADOG_RUM_PLATFORM_CLIENT_TOKEN;

datadogLogs.init({
  clientToken: ddClientToken,
  site: 'us3.datadoghq.com',
  service: ddService,
  env: process.env.NODE_ENV,
  version: process.env.REACT_APP_DEPLOYED_SHA,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
});

window.onerror = function (message, source, lineno, colno, error) {
  datadogLogs.logger.error(error?.message || '', {
      error: { stack: error?.stack },
  });
};

datadogRum.init({
    applicationId: isWidget ? process.env.REACT_APP_DATADOG_RUM_WIDGET_APPLICATION_ID : process.env.REACT_APP_DATADOG_RUM_PLATFORM_APPLICATION_ID,
    clientToken: ddClientToken,
    site: 'us3.datadoghq.com',
    service: ddService,
    env: process.env.NODE_ENV,
    version: process.env.REACT_APP_DEPLOYED_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow'
});

datadogRum.startSessionReplayRecording();

// H.init(isWidget ? process.env.REACT_APP_HIGHLIGHT_WIDGET_PROJECT_ID : process.env.REACT_APP_HIGHLIGHT_PLATFORM_PROJECT_ID, {
//   environment: 'production',
//   enableStrictPrivacy: false,
//   networkRecording: {
//     enabled: true,
//     recordHeadersAndBody: true,
//   },
//   version: process.env.REACT_APP_DEPLOYED_SHA,
// });

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <ErrorBoundary showDialog>
                  <SnackbarProvider>
                  <SnackbarBoxProvider>
                    <BackdropProvider>
                      <AuthProvider>
                        <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
                          <PostHogProvider client={posthog}>
                            <App />
                          </PostHogProvider>
                        </IntercomProvider>
                      </AuthProvider>
                    </BackdropProvider>
                  </SnackbarBoxProvider>
                  </SnackbarProvider>
                </ErrorBoundary>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </StrictMode>, document.getElementById('root')
);
