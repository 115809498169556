import strapiAxiosInstance from "./strapiAxiosInstance";

type InsightData = string;

class InstantInsightApi {
  async getInstantInsights() {
    const response = await strapiAxiosInstance(
      `/instant-insights-company/instant-insights`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    return response.data;
  }
  async getGoogleBusinessAccounts(search: InsightData) {
    const response = await strapiAxiosInstance(
      `instant-insights-companies/get-google-business-accounts${search}`,
      {
        method: "POST",
      }
    );
    return response.data;
  }
  async googleCallback(search: InsightData, selectedAccount = {}) {
    const response = await strapiAxiosInstance(
      `instant-insights-companies/auth/google/callback${search}`,
      {
        method: "POST",
        data : {googleLocation: selectedAccount},
      }
    );
    return response.data;
  }
}

export const instantInsightApi = new InstantInsightApi();
