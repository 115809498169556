import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
// import Login from '../pages/authentication/Login';
import { getCompanyInfo } from '@src/slices/dashboard';
import { useSelector } from 'react-redux';

const AuthGuard = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { features } = useSelector(getCompanyInfo);

  const companyFeatures = auth?.user?.companyData?.features || features || {};

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Navigate to={`/authentication/login${requestedLocation ? `?redirect=${location.pathname}` : ''}`} />;
  }

  if (!companyFeatures?.website && companyFeatures?.instantInsights){
    return <Navigate to={`/instant-insights`} />;
  }

  if (!companyFeatures?.website && companyFeatures?.socialMedia && location.pathname === '/dashboard/overview'){
    return <Navigate to={`/dashboard/social-media-management`} />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
