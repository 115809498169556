import { Box } from "@mui/system";
import InstantInsightsAIReport from "./components/instant-insights-ai-report";
import { Report } from "./instant-insights";

const InstantInsightsContainer = ({
  IICompany,
  pollForGenerationStatus,
}: {
  IICompany: any;
  pollForGenerationStatus: boolean;
}) => {
  return (
    <Box sx={{maxWidth: "753px" }}>
      {(IICompany?.reports || []).map((report: Report, i: number) => {
        return (
          <InstantInsightsAIReport
            report={report}
            key={i}
            companyName={IICompany.company.companyName}
            pollForGenerationStatus={pollForGenerationStatus}
          />
        );
      })}
    </Box>
  );
};

export default InstantInsightsContainer;
