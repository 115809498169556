import WidgetsIcon from "@mui/icons-material/Widgets";
import { Avatar, Box } from "@mui/material";

const InstantInsightNavbar = ({ onSidebarMobileOpen }: any) => {
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 15,
        left: 15,
        display: { lg: "none", md: "none" },
      }}
    >
      <Avatar
        onClick={onSidebarMobileOpen}
        sx={{
          backgroundColor: "white",
          border: "3px solid black",
          width: "60px",
          height: "60px",
          color: "black",
        }}
      >
        <WidgetsIcon fontSize="large" />
      </Avatar>
    </Box>
  );
};

export default InstantInsightNavbar;
