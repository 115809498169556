import {
  LinearProgress,
  Skeleton,
  Theme,
  Typography,
  linearProgressClasses,
  styled,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 9,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "white",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: "#FF790B",
  },
}));

const ReportLoadingTemplate = ({ index }: { index: number }) => {
  const isMobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <>
      <Box
        sx={{
          boxSizing: "border-box",
          height: "38px",
          width: "1px",
          border: "1px solid black",
          margin: "0 auto",
        }}
      ></Box>
      <Box sx={{ backgroundColor: "white" }}>
        <BorderLinearProgress variant="determinate" value={33} />
        <Box display="flex" justifyContent="flex-end">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "53px",
              width: "72px",
              backgroundColor: "black",
              color: "white",
              mt: -1,
              zIndex: 99
            }}
          >
            <FavoriteIcon sx={{height: "16px", width: "16px"}}/>
          </Box>
        </Box>
        <Box sx={{ p: isMobileView ? 3 : 10, pt: 0 }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              rowGap: 2,
            }}
          >
            <Typography
              color="#FF790B"
              sx={{
                fontFamily: "serif",
                fontSize: {
                  xs: "40px",
                  md: "48px",
                },
                lineHeight: {
                  xs: "43px",
                  md: "52px",
                },
                fontWeight: "bold",
              }}
            >
              {index < 10 && 0}
              {index+1}
            </Typography>
            <Typography
              align="center"
              sx={{
                fontFamily: "Lato",
                fontSize: {
                  xs: "26px",
                  md: "36px",
                },
                fontWeight: "900",
                lineHeight: {
                  xs: "29px",
                  md: "39px",
                },
                letterSpacing: {
                  xs: "-0.58px",
                  md: "-0.8px",
                },
              }}
            >
              Generating next insight...
            </Typography>
          </Box>

          <Box display="flex" sx={{ mt: 6, columnGap: 3, width: "100%" }}>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ mt: 6, rowGap: 1, width: "15%" }}
            >
              {[...Array(10)].map((_, skeletonIndex: number) => (
                <Skeleton
                  key={skeletonIndex}
                  variant="text"
                  sx={{ fontSize: "1.3rem" }}
                />
              ))}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              sx={{ mt: 6, rowGap: 1, width: "85%" }}
            >
              {[...Array(10)].map((_, skeletonIndex: number) => (
                <Skeleton
                  key={skeletonIndex}
                  variant="text"
                  sx={{
                    fontSize: "1.3rem",
                    width: skeletonIndex === 9 ? "50%" : "100%",
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReportLoadingTemplate;
