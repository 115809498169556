import { createContext, useCallback, useState, useContext } from 'react';
import { Snackbar } from '@mui/material';
import PropTypes from 'prop-types';
import AlertBox from '../components/alert-box/alert-box';
import { Box } from '@mui/system';

/**
 * @typedef Severity
 * @type {'error' |'success'}
 */

/**
 * @type {React.Context<{severity: Severity, showSnackbarBox: boolean, setShowSnackbarBox: ({ severity: Severity, title: string, message: string }) => void, title: string, message: string, setCloseSnackbarBox: Function}>}
 */
export const snackbarBoxContext = createContext({
  showSnackbarBox: false,
  title: '',
  message: '',
  severity: 'success',
  setShowSnackbarBox: () => {},
  setCloseSnackbarBox: () => {},
});

const { Provider } = snackbarBoxContext;

export const SnackbarBoxProvider = ({ children }) => {
  const [state, setState] = useState({
    showSnackbarBox: false,
    message: '',
    title: '',
    severity: 'success',
  });
  const setShowSnackbarBox = useCallback((props) => setState({
    showSnackbarBox: true,
    ...props
  }), []);
  const setCloseSnackbarBox = useCallback(() => setState((prevState) => ({
    ...prevState,
    showSnackbarBox: false,
  })), []);
  return (
    <Provider value={{ ...state, setShowSnackbarBox, setCloseSnackbarBox }}>
      {children}
    </Provider>
  );
};

SnackbarBoxProvider.propTypes = {
  children: PropTypes.node,
};

export const SnackbarBoxComponent = () => {
  const { showSnackbarBox, severity, title, message, setCloseSnackbarBox } = useContext(snackbarBoxContext);
  return (
    <Snackbar
      open={showSnackbarBox}
      autoHideDuration={3000}
      onClose={setCloseSnackbarBox}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Box component="span" sx={{ minWidth: '600px' }}>
        <AlertBox
          onClose={setCloseSnackbarBox}
          message={message}
          title={title}
          type={severity}
        />
      </Box>
    </Snackbar>
  );
};
