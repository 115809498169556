import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {
  Avatar,
  Theme,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Box } from "@mui/system";
import { Report } from "../instant-insights";
import ReportTemplate from "./instant-insights-report-template";
import ReportLoadingTemplate from "./instant-insights-report-loading";
import ScrollSpy from "react-ui-scrollspy";

type InstantInsightsAIReportProps = {
  report: Report;
  companyName: string;
  pollForGenerationStatus: boolean;
};

const DateFormatter = ({ dateString }: { dateString: string }) => {
  const inputDate = new Date(dateString);

  const options: any = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = inputDate.toLocaleDateString("en-US", options);

  return <Typography sx={{
        fontFamily: "Lato",
        fontWeight: "bold",
        opacity: "0.5",
        fontSize: {
          xs: "13px",
          md: "14px",
    }
  }}>{formattedDate}</Typography>;
};

const InstantInsightsAIReport = ({
  report,
  companyName,
  pollForGenerationStatus
}: InstantInsightsAIReportProps) => {
  const isMobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const ReportHeader = () => {
    return (
      <Box sx={{ p: 4, color: "white", background: "linear-gradient(to bottom, #FF790B 0%, #FF790B 66.66%, #F0710A 66.66%, #F0710A 100%);" }}>
        <Box
          display="flex"
          flexDirection={isMobileView ? "column-reverse" : "row"}
          justifyContent={!isMobileView ? "space-between" : "center"}
          alignItems="center"
          sx={{ rowGap: 2 }}
        >
          <img src="/static/instant_insights/logo-white.png" />
          <DateFormatter dateString={report.created_at} />
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{
            mt: {
              xs: 0,
              md: 9,
            }
          }}
        >
          <Typography
            align="center"
            sx={{
              fontFamily: "Lato",
              fontSize: {
                xs: "12px",
                md: "14px",
              },
              letterSpacing: {
                xs: "6px",
                md: "7px",
              },
              mt: 2,
              mb: 2
            }}
          >GENERATED FOR</Typography>
          <Typography align="center" sx={{
              fontFamily: "Lato",
              fontWeight: "900",
              fontSize: {
                xs: "36px",
                md: "44px",
              },
              letterSpacing: {
                xs: "-0.2px",
              md: 0
              },
              lineHeight: {
                xs: "36px",
                md: "48px",
            }
          }}>
            {companyName.toLocaleUpperCase()}
          </Typography>
          <Avatar
            sx={{
              backgroundColor: "white",
              width: "72px",
              height: "72px",
              mt: 8,
            }}
          >
            <ArrowDownwardIcon sx={{ color: "#FF790B" }} fontSize="large" />
          </Avatar>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <ReportHeader />
      <Box
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        sx={{
          rowGap: 2,
          mt: 2,
          pl: isMobileView ? 1 : 0,
          pr: isMobileView ? 1 : 0,
        }}
      >
        <ScrollSpy scrollThrottle={10}>
          {report?.content?.map((reportContent, index) => {
            const value = `${reportContent?.title?.replace(/\s/g, "-")?.toLowerCase()}`;
            return (
              <Box id={value} key={index}>
                <ReportTemplate
                  reportContent={reportContent}
                  reportContentIndex={index}
                  />
                </Box>
            );
          })}
        </ScrollSpy>
        {pollForGenerationStatus && (
            <ReportLoadingTemplate index={report?.content?.length || 0} />
          )}
      </Box>
    </>
  );
};

export default InstantInsightsAIReport;
