import { Avatar, Box, Button, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { alpha } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import PropTypes from 'prop-types';

const Modal10 = React.forwardRef(({cancel, confirm}, ref) => (
  <Box
    sx={{
      // backgroundColor: 'background.default',
      minHeight: '100%',
      p: 3
    }}
    ref={ref}
  >
    <Container maxWidth="sm">
      <Paper
        elevation={12}
        sx={{ p: 3 }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: (theme) => alpha(theme.palette.success.main, 0.08),
              color: 'warning.main',
              mb: 2
            }}
          >
            <WarningIcon />
          </Avatar>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            Unsaved Changes
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            Are you sure you want to leave this page?
            <br />
            Any unsaved progress will be lost.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            mt: 4
          }}
        >
          <Button
            color="primary"
            fullWidth
            size="large"
            sx={{ mr: 2 }}
            variant="outlined"
            onClick={cancel}
          >
            NO
          </Button>
          <Button
            color="primary"
            fullWidth
            size="large"
            variant="contained"
            onClick={confirm}
          >
            OK
          </Button>
        </Box>
      </Paper>
    </Container>
  </Box>
));

Modal10.propTypes = {
  confirm: PropTypes.func,
  cancel: PropTypes.func,
};

export default Modal10;
