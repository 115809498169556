import strapiAxiosInstance from './strapiAxiosInstance';

class GlobalApi {
  async findSettings() {
    const response = await strapiAxiosInstance.get('/public-settings');
    return response.data;
  }
}

export const globalApi = new GlobalApi();
