import {
  Avatar,
  Box,
  Divider,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { ReactElement, useEffect, useRef } from "react";
import StarIcon from "@mui/icons-material/Star";
import PeopleIcon from "@mui/icons-material/People";
import BrushIcon from "@mui/icons-material/Brush";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  IconType,
  ListItemStyleType,
  ReportBlock,
  ReportParagraph,
} from "../instant-insights";
import ReactMarkdown from "react-markdown";

type ReportTemplateProps = {
  reportContent: ReportBlock;
  reportContentIndex: number;
};

type ReportContentBlockType = {
  content: ReportParagraph;
  listItemStyle: ListItemStyleType | undefined | null;
  index: number;
  contentLength: number;
};

const ReportTemplate = ({
  reportContent,
  reportContentIndex,
}: ReportTemplateProps) => {
  const isMobileView = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const iconSwitcher = (iconName: IconType) => {
    const sx: SxProps = {height: "16px", width: "16px"};
    switch (iconName) {
      case "star":
        return <StarIcon sx={sx} />;
      case "people":
        return <PeopleIcon  sx={sx} />;
      case "brush":
        return <BrushIcon  sx={sx} />;
      case "heart":
        return <FavoriteIcon  sx={sx} />;
      default:
        return <StarIcon  sx={sx} />;
    }
  };

  const RoundedBox = ({ content }: { content: number | ReactElement }) => {
    return (
      <Avatar
        sx={{
          backgroundColor: "white",
          color: "#FF790B",
          border: {
            xs: "1px solid black",
            md: "2px solid black",
          },
          fontWeight: "bold",
          width: {
            xs: "34px",
            md: "50px",
          },
          height: {
            xs: "34px",
            md: "50px",
          },
          fontSize: "16px",
        }}
      >
        {content}
      </Avatar>
    );
  };

  const ReportContentBlock = ({
    content,
    listItemStyle,
    index,
    contentLength,
  }: ReportContentBlockType) => {
    return (
      <>
        {listItemStyle ? (
          <>
            <Box display="flex" alignItems="center" columnGap={3}>
              <RoundedBox
                content={
                  listItemStyle === "numbers" ? (
                    index + 1
                  ) : (
                    <ArrowForwardIcon fontSize="medium" />
                  )
                }
              />
              <Box display="flex" flexDirection="column" rowGap={2} >
                {content?.heading && (
                  <Typography variant="h5">{content.heading}</Typography>
                )}
                <Typography color="#4B5353" sx={{
                  fontSize: {
                    xs: "16px",
                    md: "18px"
                    },
                  lineHeight: {
                    xs: "26px",
                    md: "28px"
                  },
                  fontFamily: "Lato",
                }}>
                  <ReactMarkdown source={content?.text} />
                </Typography>
              </Box>
            </Box>

            {contentLength - 1 > index && <Divider color="black" />}
          </>
        ) : (
          <Box display="flex" alignItems="flex-start">
            <Box minWidth="50px" sx={{
              ml: -6,
              mr: 3,
              mt: 1,
              display: {
                xs: "none",
                md: "block"
              }
            }}>
              {index === 0 && (
                <RoundedBox content={<ArrowForwardIcon fontSize="medium" />} />
              )}
            </Box>
            <Typography color="#4B5353" sx={{fontSize: {xs: "16px", md: "18px"}, lineHeight: {xs: "26px", md: "28px"}, fontFamily: "Lato"}}>
              <ReactMarkdown source={content?.text?.replace(/\n/g, "\n\n")} />
            </Typography>
          </Box>
        )}
      </>
    );
  };
  

  return (
    <>
      <Box sx={{
        boxSizing: "border-box",
        height: "38px",
        width: "1px",
        border: "1px solid black",
        margin: "0 auto"
      }}></Box>
    <Box sx={{ backgroundColor: "white", mt: 2, mb: 2 }}>
      <Box display="flex" justifyContent="flex-end">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "53px",
            width: "72px",
            backgroundColor: "black",
            color: "white",
          }}
        >
          {iconSwitcher(reportContent?.icon)}
        </Box>
      </Box>
      <Box sx={{ p: isMobileView ? 3 : 10, pt: 0 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            rowGap: 2,
          }}
        >
          <Typography color="#FF790B" sx={{
            fontFamily: 'serif',
            fontSize: {
              xs: '40px',
              md: '48px',
            },
            lineHeight: {
              xs: '43px',
              md: '52px',
            },
            fontWeight: 'bold',
          }}>
            {reportContentIndex + 1 < 10 && 0}
            {reportContentIndex + 1}
          </Typography>
          <Typography align="center" sx={{
            fontFamily: "Lato",
            fontSize: {
              xs: "26px",
              md: "36px",
            },
            fontWeight: "900",
            lineHeight: {
              xs: "29px",
              md: "39px",
            },
            letterSpacing: {
              xs: "-0.58px",
              md: "-0.8px",
            },
          }}>
            {reportContent?.title.toLocaleUpperCase()}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" sx={{ mt: 4, rowGap: 3 }}>
          {reportContent?.content?.map(
            (content: ReportParagraph, key: number) => {
              return (
                <ReportContentBlock
                  content={content}
                  key={key}
                  index={key}
                  contentLength={reportContent.content.length}
                  listItemStyle={reportContent?.list_item_style}
                />
              );
            }
          )}
        </Box>
      </Box>
    </Box>
    </>

  );
};

export default ReportTemplate;
