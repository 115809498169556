import { createContext, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Backdrop, CircularProgress } from '@mui/material';

export const backdropContext = createContext({
  showBackdrop: false,
  setShowBackdrop: () => {},
  setCloseBackdrop: () => {},
});

const { Provider } = backdropContext;

export const BackdropProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const setShowBackdrop = useCallback(() => setShow(true), []);
  const setCloseBackdrop = useCallback(() => setShow(false), []);
  return (
    <Provider value={{ showBackdrop: show, setShowBackdrop, setCloseBackdrop }}>
      {children}
    </Provider>
  );
};

BackdropProvider.propTypes = {
  children: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export const BackdropComponent = () => {
  const classes = useStyles();
  const { showBackdrop } = useContext(backdropContext);
  return (
    <Backdrop
      className={classes.backdrop}
      open={showBackdrop}
    >
      <CircularProgress
        size={60}
        thickness={2}
        color="primary"
      />
    </Backdrop>
  );
};
