import { createContext, useCallback, useState, useContext } from 'react';
import { Alert, Snackbar } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * @typedef Severity
 * @type {'error' | 'info' | 'success' | 'warning'}
 */

/**
 * @type {React.Context<{severity: Severity, showSnackbar: boolean, setShowSnackbar: ({ severity: Severity, message: string }) => void, message: string, setCloseSnackbar: Function}>}
 */
export const snackbarContext = createContext({
  showSnackbar: false,
  message: '',
  severity: 'success',
  setShowSnackbar: () => {},
  setCloseSnackbar: () => {},
});

const { Provider } = snackbarContext;

export const SnackbarProvider = ({ children }) => {
  const [state, setState] = useState({
    showSnackbar: false,
    message: '',
    severity: 'success',
  });
  const setShowSnackbar = useCallback((props) => setState({
    showSnackbar: true,
    ...props
  }), []);
  const setCloseSnackbar = useCallback(() => setState((prevState) => ({
    ...prevState,
    showSnackbar: false,
  })), []);
  return (
    <Provider value={{ ...state, setShowSnackbar, setCloseSnackbar }}>
      {children}
    </Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.node,
};

export const SnackbarComponent = () => {
  const { showSnackbar, severity, message, setCloseSnackbar } = useContext(snackbarContext);
  return (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={3000}
      onClose={setCloseSnackbar}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      <Alert
        onClose={setCloseSnackbar}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
